<template>
  <div class="card-users-create">
    <form-header
      class="header-card"
      title="Crear administrador"
      @back-action="$router.go(-1)"
    />
    <b-row class="z-form-section">
      <b-col lg="6">
        <div>
          <div class="z-section">Información personal</div>
          <b-row class="form-group">
            <b-col md="9">
              <label for="name">Nombre (s)</label>
              <b-input
                id="name"
                v-model="name"
                :state="nameState"
                type="text"
                placeholder="Nombre"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="email">Correo electrónico</label>
              <b-input
                id="email"
                v-model="email"
                :state="emailState"
                type="text"
                placeholder="correo@mail.com"
              />
              <b-form-invalid-feedback id="email-feedback">
                Ingresa un correo valido ej. example@zubut.com
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="password">Contraseña</label>
              <b-input
                id="password"
                v-model="password"
                :state="passwordState"
                type="password"
                placeholder="Minimo 6 digitos"
              />
            </b-col>
          </b-row>
          <b-row class="form-group">
            <b-col md="9">
              <label for="confirm-pass">Confirmar constraseña</label>
              <b-input
                id="confirm-pass"
                v-model="confirmPass"
                :state="confirmPassState"
                type="password"
                placeholder="Confirmar contraseña"
              />
              <b-form-invalid-feedback id="confirm-pass-feedback">
                Las contraseñas deben coincidir
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <quick-message
            with-icon
            :show.sync="hasError"
            :message="message"
            type="error"
          />
        </div>
      </b-col>
      <b-col class="z-wrap overflow-auto" lg="6">
        <div>
          <div class="z-section">Rol de operaciones y permisos</div>
          <b-row class="form-group">
            <b-col md="9">
              <label for="name">Rol</label>
              <z-dropdown
                v-model="role"
                :options="roles"
                variant="white"
                :show-header="false"
                placeholder="Selecciona un rol"
              />
            </b-col>
          </b-row>
          <b-row v-show="showRoleAccess" class="form-group">
            <b-col md="9">
              <b-form-group>
                <label for="permissions">Permisos en predeterminado</label>
                <p>
                  Recuerda que puedes modificar los permisos en
                  <span class="action">Configuración avanzada</span>
                </p>
                <b-row>
                  <b-col
                    v-for="(access, key) in defaultAccess"
                    :key="key"
                    cols="12"
                    class="mt-3"
                  >
                    <admin-role-access
                      :access="key"
                      :actions="access"
                      @update-access="updateAccess"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="action-buttons">
        <z-button id="cancel-btn" variant="secondary" @click="handleCancel">
          Cancelar
        </z-button>
        <z-button
          id="create-btn"
          :disabled="!validFields"
          :loading="isLoading"
          @click="handleCreateAdmin"
        >
          Crear administrador
        </z-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _assign from "lodash/assign";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import FormHeader from "@/app/components/FormHeader.vue";
import AdminRoleAccess from "./AdminRoleAccess";
import notifyMixin from "@/mixins/notify";
import RoleType from "@/constants/roles/type";
import { validateEmail, validateName } from "@/utils/strings";

export default {
  name: "AdminsCreate",

  components: {
    AdminRoleAccess,
    FormHeader,
    ZDropdown
  },

  mixins: [notifyMixin("notifications")],

  data() {
    return {
      name: "",
      email: "",
      password: "",
      confirmPass: "",
      role: null,
      defaultAccess: null,
      editedAccess: null,
      userType: null,
      vueTel: "",
      isLoading: false,
      permissions: [
        { text: "Ver listado de servicios", value: 1 },
        { text: "Ver detalle de servicios", value: 2 },
        { text: "Asignar servicios", value: 3 }
      ],
      hasError: false,
      message: ""
    };
  },

  computed: {
    roles() {
      return RoleType.options;
    },
    nameState() {
      return this.name.length === 0 ? null : validateName(this.name);
    },
    emailState() {
      return this.email.length === 0 ? null : validateEmail(this.email);
    },
    passwordState() {
      return this.password.length === 0
        ? null
        : this.password.length > 2
        ? true
        : false;
    },
    confirmPassState() {
      return this.confirmPass.length === 0
        ? null
        : this.confirmPass === this.password
        ? true
        : false;
    },
    roleState() {
      return this.role != null;
    },
    showRoleAccess() {
      return this.defaultAccess != null;
    },
    validFields() {
      return (
        this.nameState &&
        this.emailState &&
        this.passwordState &&
        this.confirmPassState &&
        this.roleState
      );
    }
  },

  watch: {
    role(newRole, oldRole) {
      if (newRole == null) {
        return;
      }
      if (newRole < 0) {
        this.role = null;
        this.defaultAccess = null;
        return;
      }
      if (oldRole == null || oldRole != newRole) {
        this.getDefaultAccess(newRole);
      }
    }
  },

  methods: {
    updateAccess(god) {
      this.editedAccess = _assign({}, this.editedAccess, god);
    },

    getDefaultAccess(role) {
      this.$store
        .dispatch("admin/getDefaultRoleAccess", role)
        .then(res => {
          this.defaultAccess = res.access;
          this.editedAccess = Object.assign({}, res.access);
        })
        .catch(err => {
          this.$captureError(err);
          console.error("getting rol", err);
        });
    },

    handleCancel() {
      // Clean data
      this.$router.push({ name: "admins" });
    },

    handleCreateAdmin() {
      this.isLoading = true;
      const role = this.role;
      const data = {
        email: this.email.toLowerCase(),
        password: this.password,
        name: this.name
      };
      const access = this.editedAccess;
      this.$store
        .dispatch("admin/createAdmin", { data, role, access })
        .then(() => {
          this.notify({
            title: "Usuario creado con exito",
            text: `Usuario ${this.email} creado`,
            type: "success"
          });
          this.$router.push({ name: "admins" });
        })
        .catch(err => {
          this.$captureError(err);
          this.hasError = true;
          this.message = err.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-users-create {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  border: 1px solid darken($white-smoke, 5%);
  background-color: $white;
  padding: 0em 2em;
  margin: 1em;
  flex: 1;

  .header-card {
    flex: 1;
  }

  .z-form-section {
    margin-top: 1rem;
    background-color: $white;
    border: 1px solid $gainsboro;
    border-radius: 8px;
    padding: 24px;

    .z-wrap {
      max-height: 550px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .z-wrap::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE and Edge */
    .z-wrap {
      -ms-overflow-style: none;
    }

    .action {
      color: $zubut-blue;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .z-section {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $nero;
      margin-bottom: 1.5rem;
    }

    p {
      color: $dim-gray;
      font-size: 12px;
      cursor: default;
    }

    .form-group {
      margin-bottom: 1rem;
    }

    .form-group label {
      font-size: 12px;
      color: $nero;
    }
  }

  .action-buttons {
    margin: 2rem 0em;

    button {
      margin-right: 1rem;
      height: 40px;
    }
  }
}
</style>

<style lang="scss">
.card-users-create {
  .form-control.is-valid {
    border-color: $link-water;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($zubut-blue, 0.25);
    }
  }
}
</style>
